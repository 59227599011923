@use "/src/styles/mixins/ff" as *;
@import "/src/styles/mixin";

//.new,
//.sale,
//.license,
//.default,
//.delivery,
//.firstWeapon {
//  @include font12;
//  font-weight: 500;
//  padding: 4px 8px;
//  white-space: nowrap;
//  text-align: center;
//  border-radius: var(--border-radius-small);
//  display: flex;
//  align-items: center;
//  gap: 4px;
//  @media only screen and (max-width: 767.98px) {
//    font-size: 9px;
//    line-height: 16px;
//  }
//}

.label {
  @include ff(12px, 400, 20px);
  padding: 4px 8px;
  white-space: nowrap;
  border-radius: var(--border-radius-small);
  display: flex;
  align-items: center;
  gap: 4px;

  &.light-green {
    background-color: var(--label-new-color);
  }

  &.green {
    background-color: var(--green-secondary);
    color: var(--white-color);
  }

  &.beige {
    background-color: var(--beige-color-100);
    color: var(--secondary-color);
  }

  &.yellow {
    background-color: var(--label-sale-color);
    color: var(--secondary-color);
  }

  &.light-yellow {
    background-color: var(--light-yellow-color);
    color: var(--secondary-color);
  }

  &.middle-yellow {
    background-color: var(--middle-yellow-color);
    color: var(--secondary-color);
  }

  &.bright-yellow {
    background-color: var(--hover-primary-color);
    color: var(--secondary-color);
  }

  &.white {
    background-color: var(--white-color);
    color: var(--secondary-color);
  }

  &.gray-red {
    background-color: var(--bar-3);
    color: var(--red-0);
  }

  &.gray {
    background-color: var(--grey-darker-color);
    color: var(--white-color);
  }

  &.border {
    border: 1px solid var(--secondary-color);
  }

  &.default {
    border: none;
  }

  &.small {
    padding: 2px 4px;
  }

  .icon {
    width: 20px;
    height: auto;
  }

  @media only screen and (max-width: 767.98px) {
    padding: 2px 4px;
    @include ff(10px, 600, 16px);
  }
}

//.new {
//  background-color: var(--label-new-color);
//}
//
//.license {
//  background-color: var(--label-license-color);
//}
//
//.sale {
//  background-color: var(--label-sale-color);
//}
//
//.default {
//  background-color: var(--label-new-color);
//}
//
//.delivery {
//  // margin-top: auto;
//  padding: 0px 8px;
//  background-color: var(--primary-color);
//  font-size: 11px;
//}
//
//
//.firstWeapon {
//  background-color: var(--green-secondary);
//  color: var(--white-color);
//}
